import React from "react"

const Fb = () => (
    <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Facebook icon</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="" fillRule="evenodd">
            <g id="facebook-square" fill="" fillRule="nonzero">
                <path d="M50,5.35714286 L50,44.6428571 C50,47.6004464 47.6004464,50 44.6428571,50 L35.1227679,50 L35.1227679,30.2232143 L41.8861607,30.2232143 L42.8571429,22.6785714 L35.1227679,22.6785714 L35.1227679,17.8571429 C35.1227679,15.6696429 35.7254464,14.1852679 38.8616071,14.1852679 L42.8571429,14.1852679 L42.8571429,7.44419643 C42.1651786,7.35491071 39.7991071,7.14285714 37.03125,7.14285714 C31.2723214,7.14285714 27.3214286,10.6584821 27.3214286,17.1205357 L27.3214286,22.6897321 L20.5357143,22.6897321 L20.5357143,30.234375 L27.3325893,30.234375 L27.3325893,50 L5.35714286,50 C2.39955357,50 0,47.6004464 0,44.6428571 L0,5.35714286 C0,2.39955357 2.39955357,0 5.35714286,0 L44.6428571,0 C47.6004464,0 50,2.39955357 50,5.35714286 Z" id="Shape"></path>
            </g>
        </g>
    </svg>

)

export default Fb