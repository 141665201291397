import React from "react"

const Linkedin = () => (
    <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Linkedin icon</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="" fillRule="evenodd">
            <g id="linkedin" fill="" fillRule="nonzero">
                <path d="M46.4285714,0 L3.56026786,0 C1.59598214,0 0,1.61830357 0,3.60491071 L0,46.3950893 C0,48.3816964 1.59598214,50 3.56026786,50 L46.4285714,50 C48.3928571,50 50,48.3816964 50,46.3950893 L50,3.60491071 C50,1.61830357 48.3928571,0 46.4285714,0 Z M15.1116071,42.8571429 L7.70089286,42.8571429 L7.70089286,18.9955357 L15.1227679,18.9955357 L15.1227679,42.8571429 L15.1116071,42.8571429 Z M11.40625,15.7366071 C9.02901786,15.7366071 7.109375,13.8058036 7.109375,11.4397321 C7.109375,9.07366071 9.02901786,7.14285714 11.40625,7.14285714 C13.7723214,7.14285714 15.703125,9.07366071 15.703125,11.4397321 C15.703125,13.8169643 13.7834821,15.7366071 11.40625,15.7366071 Z M42.890625,42.8571429 L35.4799107,42.8571429 L35.4799107,31.25 C35.4799107,28.4821429 35.4241071,24.921875 31.6294643,24.921875 C27.7678571,24.921875 27.1763393,27.9352679 27.1763393,31.0491071 L27.1763393,42.8571429 L19.765625,42.8571429 L19.765625,18.9955357 L26.875,18.9955357 L26.875,22.2544643 L26.9754464,22.2544643 C27.96875,20.3794643 30.390625,18.4040179 33.9955357,18.4040179 C41.4955357,18.4040179 42.890625,23.3482143 42.890625,29.7767857 L42.890625,42.8571429 Z" id="Shape"></path>
            </g>
        </g>
    </svg>

)

export default Linkedin