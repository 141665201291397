import React, { useCallback, useState } from 'react'
import { Link } from "gatsby"
import Logo from "../images/launch-lab-logo.svg"
import useWindowSize from '../hooks/useWindowSize'
import classNames from 'classnames'

export default function Header() {
  const { width } = useWindowSize();
  const useSmallMenu = width < 768;

  const [isMobileOpen, setMobileOpen] = useState(false);
  const onToggleMobileOpen = useCallback(() => setMobileOpen(!isMobileOpen), [isMobileOpen]);

  // const activeStyles = {
  //   textDecoration: 'underline'
  // };

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="navbar flex flex-ac flex-sb">
            <Link to="/" className="logo flex flex-ac">
              <img src={Logo} alt="Launch Lab logo" />
            </Link>

            {(!useSmallMenu || isMobileOpen) &&
            (<nav className={classNames({'mobile-menu': useSmallMenu})}>
              <Link to="/about/" className='nav-link' activeClassName="active">About</Link>
              <Link to="/services/" className='nav-link' activeClassName="active">Services</Link>
              <Link to="/portfolio/" className='nav-link' activeClassName="active">Work</Link>
              <Link to="/blog/" className='nav-link' activeClassName="active">Blog</Link>
              <Link to="/contact/" className='btn btn-sm btn-line btn-mob'>
                Contact
              </Link>
            </nav>)}
            
            {useSmallMenu &&
            (<button onClick={onToggleMobileOpen} className='btn-mob-nav'>
              {isMobileOpen ? (<div id="nav-icon4" className="open">
                <span></span>
                <span></span>
                <span></span>
              </div>) : (<div id="nav-icon4">
                <span></span>
                <span></span>
                <span></span>
              </div>)}
            </button>)}
            
          </div>
        </div>
      </div>
    </header>
  )
}

