import React from "react"

const Twitter = () => (
    <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Twitter icon</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="" fillRule="evenodd">
            <g id="twitter-square" fill="" fillRule="nonzero">
                <path d="M44.6428571,0 L5.35714286,0 C2.39955357,0 0,2.39955357 0,5.35714286 L0,44.6428571 C0,47.6004464 2.39955357,50 5.35714286,50 L44.6428571,50 C47.6004464,50 50,47.6004464 50,44.6428571 L50,5.35714286 C50,2.39955357 47.6004464,0 44.6428571,0 Z M39.1852679,17.7232143 C39.2075893,18.0357143 39.2075893,18.359375 39.2075893,18.671875 C39.2075893,28.3482143 31.8415179,39.4977679 18.3816964,39.4977679 C14.2299107,39.4977679 10.3794643,38.2924107 7.14285714,36.2165179 C7.734375,36.2834821 8.30357143,36.3058036 8.90625,36.3058036 C12.3325893,36.3058036 15.4799107,35.1450893 17.9910714,33.1808036 C14.7767857,33.1138393 12.0758929,31.0044643 11.1495536,28.1026786 C12.2767857,28.2700893 13.2924107,28.2700893 14.453125,27.96875 C11.1049107,27.2879464 8.59375,24.3415179 8.59375,20.78125 L8.59375,20.6919643 C9.56473214,21.2388393 10.703125,21.5736607 11.8973214,21.6183036 C9.85808946,20.2618714 8.63438325,17.9737084 8.63839286,15.5245536 C8.63839286,14.1629464 8.99553571,12.9129464 9.63169643,11.8303571 C13.2366071,16.2723214 18.6495536,19.1741071 24.7209821,19.4866071 C23.6830357,14.5200893 27.3995536,10.4910714 31.8638393,10.4910714 C33.9732143,10.4910714 35.8705357,11.3727679 37.2098214,12.8013393 C38.8616071,12.4888393 40.4464286,11.875 41.8526786,11.0379464 C41.3058036,12.734375 40.15625,14.1629464 38.6383929,15.0669643 C40.1116071,14.9107143 41.5401786,14.4977679 42.8571429,13.9285714 C41.8638393,15.390625 40.6138393,16.6852679 39.1852679,17.7232143 Z" id="Shape"></path>
            </g>
        </g>
    </svg>

)

export default Twitter