import {useEffect, useState} from "react";

export default function useWindowSize() {
  const [size, setSize] = useState({ width: Number.MAX_VALUE, height: Number.MAX_VALUE });

  useEffect(
    () => {
      function onResize() {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }

      window.addEventListener('resize', onResize)
      onResize()
      return () => {
        window.removeEventListener('resize', onResize)
      }
    },
    []
  );

  return size;
}
