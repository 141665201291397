import React from "react"
// import "@fontsource/inter/variable.css"
import "@fontsource/outfit/300.css"
import "@fontsource/outfit/400.css"
import "@fontsource/outfit/600.css"
import "@fontsource/outfit/700.css"
import "@fontsource/lora"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="site-wrapper">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
      >
        <div>
          {children}
          <noscript dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K8HC89T" height="0" width="0"
          style="display:none;visibility:hidden"></iframe>
          `,
            }}
          />
        </div>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
